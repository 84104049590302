import apiData from "@/api/Data"
import mapboxgl from "mapbox-gl"
import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    collections: [],
    dataset: null,
    loading: true,
    search: null,
    map: undefined,
  },
  getters: {
    collections(state) {
      return state.collections
    },
    dataset(state) {
      return state.dataset
    },
    loading(state) {
      return state.dataset
    },
    search(state) {
      return state.search
    },
  },
  mutations: {
    SET_COLLECTIONS(state, data) {
      state.collections = data
    },
    SET_DATASET(state, data) {
      state.dataset = data
    },
  },
  actions: {
    async fetchData({ commit }) {
      let data = await apiData.getData()
      commit("SET_COLLECTIONS", data.data.collections)
    },
    async setDataset({ commit, dispatch }, id) {
      if (!this.state.collections.length) {
        await dispatch("fetchData")
      }
      const dataset = this.state.collections.find((e) => e.id == id)
      commit("SET_DATASET", dataset)
    },
    initMap({ state, dispatch }, { mapId }) {
      mapboxgl.accessToken =
        "pk.eyJ1IjoidHJpZW5uZ3V5ZW4iLCJhIjoiY2xoNHV0cG1xMDI1NDNlczgxMnV1bWZqNyJ9.GcTfYm213EVxVNHWRU3Z_g"
      state.map = new mapboxgl.Map({
        container: mapId,
        style: "mapbox://styles/mapbox/light-v11",
        center: [0, 0],
        zoom: 1,
        attributionControl: false,
        projection: "equirectangular",
      })
      if (state.dataset.extent && state.dataset.extent.spatial) {
        dispatch("addLayer")
      }
    },
    addLayer({ state }) {
      let bbox = [...state.dataset.extent.spatial.bbox[0]]
      // console.log(bbox)
      let geom = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [bbox[2], bbox[1]],
                  [bbox[2], bbox[3]],
                  [bbox[0], bbox[3]],
                  [bbox[0], bbox[1]],
                  [bbox[2], bbox[1]],
                ],
              ],
            },
          },
        ],
      }
      state.map.on("load", function () {
        state.map.addLayer({
          id: state.dataset.id,
          source: {
            type: "geojson",
            data: geom,
          },
          type: "line",
          paint: {
            "line-color": "#116D6E",
            "line-width": 4,
          },
        })
      })
      state.map.fitBounds(bbox, { padding: 20 })
    },
  },
  modules: {},
})
export default store
