<template>
  <v-app>
    <AppBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './components/app-bar/AppBar';
export default {
  name: 'App',
  components: {
    AppBar,
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-main__wrap {
  display: flex;
  align-items: flex-start;
}

</style>
