import config from '@/config'
import axios from 'axios'

export default class BaseApi {
  submit(method, url, params = {}, configRequest = {}) {
    if (!url.includes('https://') && !url.includes('http://')){
      url = config.api_backend + url
    }
    configRequest = {
      method: method,
      url: url,
      params: (method === 'get' || method === 'GET') ? params : '',
      data: (method !== 'get' && method !== 'GET') ? params : '',
      headers: {
        "Content-Type": "application/json",
      },
      ...configRequest
    }
    return axios(configRequest)
  }
}