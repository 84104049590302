import config from "@/config"
import BaseApi from "./BaseApi"

class AuthApi extends BaseApi {
  login() {
    window.location.href =
      config.login_url +
      "/signIn.html" +
      "?redirect=" +
      window.location.origin +
      "/login?token="
  }
  getMe(token) {
    if (token)
      return super.submit("get", `${config.api_auth}/me`, null, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
    return super.submit("get", `${config.api_auth}/me`, null, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
  }
  logout() {
    return super.submit("post", `${config.api_auth}/logout`, null, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
  }
}

export default new AuthApi()
