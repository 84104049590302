import BaseApi from './BaseApi'

class Data extends BaseApi {
  getData(){
    return super.submit('get', '/collections')
  }
  getItemSTAC(url){
    return super.submit('get', url)
  }
  getCollection(id){
    return super.submit('get', '/collections/'+id)
  }
}

export default new Data()