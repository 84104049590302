<template>
  <v-app-bar app color="#000000" dark height="56" padding>
    <img
      src="@/assets/FINAL_EO_Factory Logo.png"
      height="24"
      @click="onClick('/')"
      class="mb-1"
    />
    <v-spacer></v-spacer>

    <div class="d-flex align-center font-menu">
      <template v-if="isAdmin">
        <div class="d-flex align-center mr-8" @click="goToAdmin()">
          <svg
            viewBox="0 0 16 16"
            width="20"
            height="20"
            focusable="false"
            role="img"
            aria-label="folder fill"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-folder-fill b-icon bi mr-1"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.625 12C1.625 12.4142 1.96079 12.75 2.375 12.75L13.3476 12.75L11.3869 14.4306C11.0724 14.7001 11.036 15.1736 11.3056 15.4881C11.5751 15.8026 12.0486 15.839 12.3631 15.5694L15.8631 12.5694C16.0293 12.427 16.125 12.2189 16.125 12C16.125 11.7811 16.0293 11.573 15.8631 11.4306L12.3631 8.43056C12.0486 8.16099 11.5751 8.19741 11.3056 8.51191C11.036 8.8264 11.0724 9.29988 11.3869 9.56944L13.3476 11.25L2.375 11.25C1.96079 11.25 1.625 11.5858 1.625 12Z"
                fill="#ffffff"
              ></path>
              <path
                d="M9.375 9.75004L9.75328 9.75004C9.49473 9.01645 9.6241 8.16876 10.1667 7.53576C10.9754 6.59228 12.3958 6.48301 13.3393 7.29171L16.8393 10.2917C17.338 10.7192 17.625 11.3432 17.625 12C17.625 12.6569 17.338 13.2809 16.8393 13.7084L13.3393 16.7084C12.3958 17.5171 10.9754 17.4078 10.1667 16.4643C9.6241 15.8313 9.49473 14.9836 9.75328 14.25L9.375 14.25L9.375 16C9.375 18.8284 9.375 20.2426 10.2537 21.1213C11.1324 22 12.5466 22 15.375 22L16.375 22C19.2034 22 20.6176 22 21.4963 21.1213C22.375 20.2426 22.375 18.8284 22.375 16L22.375 8C22.375 5.17158 22.375 3.75736 21.4963 2.87868C20.6176 2 19.2034 2 16.375 2L15.375 2C12.5466 2 11.1324 2 10.2537 2.87868C9.375 3.75736 9.375 5.17157 9.375 8L9.375 9.75004Z"
                fill="#ffffff"
              ></path>
            </g>
          </svg>
          <span>Admin Page</span>
        </div>
      </template>

      <a :href="config.api_lab" class="text-href">
        <div class="d-flex align-center mr-8">
          <svg
            viewBox="0 0 16 16"
            width="20"
            height="20"
            focusable="false"
            role="img"
            aria-label="folder fill"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-folder-fill b-icon bi mr-1"
          >
            <g>
              <path
                d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z"
              ></path>
            </g>
          </svg>
          <span>Lab</span>
        </div>
      </a>
      <!-- <a href="https://rolodextest.eofactory.ai" class="text-href">
                <div class="d-flex align-center mr-8">
                    <svg viewBox="0 0 16 16" width="20" height="20" focusable="false" role="img" aria-label="folder fill"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-folder-fill b-icon bi mr-1">
                        <g>
                            <path
                                d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z">
                            </path>
                        </g>
                    </svg>
                    <span>Rolodex</span>
                </div>
            </a> -->
      <div class="d-flex align-center mr-8" @click="onClick('/document')">
        <svg
          viewBox="0 0 16 16"
          width="20"
          height="20"
          focusable="false"
          role="img"
          aria-label="folder fill"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="bi-folder-fill b-icon bi mr-1"
        >
          <g>
            <path
              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            ></path>
          </g>
        </svg>
        <span>Document</span>
      </div>
      <!-- <div class="d-flex align-center mr-8">
                <svg viewBox="0 0 16 16" width="20" height="20" focusable="false" role="img" aria-label="folder fill"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-folder-fill b-icon bi mr-1">
                    <g>
                        <path
                            d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z">
                        </path>
                    </g>
                </svg>
                <span>Contact</span>
            </div> -->
      <!-- <div class="d-flex align-center mr-8">
                <svg viewBox="0 0 16 16" width="20" height="20" focusable="false" role="img" aria-label="folder fill"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-folder-fill b-icon bi mr-1">
                    <g>
                        <path
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z">
                        </path>
                    </g>
                </svg>
                <span>Private Policy</span>
            </div> -->

      <template v-if="!user">
        <div class="d-flex align-center mr-8" @click="() => login()">
          <span>Login</span>
        </div>
      </template>
      <template v-else>
        <v-menu v-model="showMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="rounded-circle"
              :img="
                user.picture ||
                'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg'
              "
              v-bind="attrs"
              v-on="on"
              width="32"
              height="32"
            ></v-card>
          </template>

          <v-list dense width="200">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="item.click"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </div>
  </v-app-bar>
</template>
<script>
import config from "@/config"
import AuthApi from "@/api/AuthApi"
export default {
  data() {
    return {
      config: config,
      user: null,
      showMenu: false,
      items: [
        { text: "Logout", icon: "mdi-logout", click: () => this.logout() },
      ],
    }
  },
  mounted() {
    this.getMe()
  },
  computed: {
    isAdmin() {
      return (
        this.user &&
        this.user.roles &&
        this.user.roles.includes("DATACUBE_ADMIN")
      )
    },
  },
  methods: {
    async getMe() {
      const auth = localStorage.getItem("auth")
      if (auth === "true") {
        try {
          const res = await AuthApi.getMe()
          this.user = res.data.body
        } catch (error) {
          console.log(error)
        }
      }
    },
    onClick(route) {
      if (this.$route.fullPath !== route) {
        this.$router.push(route)
      }
    },
    login() {
      AuthApi.login()
    },
    async logout() {
      await AuthApi.logout()
      localStorage.removeItem("auth")
      localStorage.removeItem("access_token")
      location.reload()
    },
    goToAdmin() {
      window.location.href = window.location.origin + "/admin"
    },
  },
}
</script>

<style lang="scss" scoped>
.font-text-logo {
  font-weight: 700;
  font-size: 20px;
  line-height: inherit;
  white-space: nowrap;
  color: #893ff2;
  cursor: pointer;
}

.font-menu {
  font-weight: 500;
  font-size: 18px;
  line-height: inherit;
  white-space: nowrap;
  color: #ffffff;
  cursor: pointer;
}

.text-href {
  text-decoration: none;
  color: #ffffff;
}
</style>
